import { App, ComponentPublicInstance } from 'vue';

export const setupGlobalErrorHandler = (app: App<Element>): void => {
  app.config.errorHandler = (
    err: unknown,
    instance: ComponentPublicInstance | null,
    info: string
  ) => {
    if (!(err instanceof Error)) {
      console.error(`Unknown error: ${err}\nInfo: ${info}`);
      return;
    }

    console.groupCollapsed(`Error: ${err.message}`);
    console.error(`Info: ${info}`);

    if (!instance) {
      console.groupEnd();
      return;
    }

    console.log(`Component Name: ${instance.$options.name || 'Anonymous Component'}`);
    logComponentDetails(instance);

    console.groupEnd();
  };
};

function logComponentDetails(instance: ComponentPublicInstance) {
  console.log('Props:', instance.$props);
  console.log('Attrs:', instance.$attrs);
  console.log('Data:', JSON.parse(JSON.stringify(instance.$data))); // Deep clone data for logging

  logDynamicProperties('Computed Properties', instance, instance.$options.computed);
  logDynamicProperties('Methods', instance, instance.$options.methods);
  logDynamicProperties('Watchers', instance, instance.$options.watch);
  logDynamicProperties('Directives', instance, instance.$options.directives);

  if (instance.$slots) {
    console.log('Slots:', Object.keys(instance.$slots));
  }
  if (instance.$options.mixins) {
    console.log('Mixins:', instance.$options.mixins.length);
  }
}

function logDynamicProperties(
  label: string,
  instance: ComponentPublicInstance,
  properties: Record<string, any> | undefined
) {
  if (!properties) return;
  console.log(label + ':');
  Object.keys(properties).forEach(key => {
    const value = (instance as any)[key];
    console.log(`${key}:`, value);
  });
}
