<template>
  <div class="modal" :class="{ open: openModal }">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { getBreadCrumbsStore } from '@/store';

export default defineComponent({
  name: 'Mob_Modal',
  props: {
    openModal: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const breadcrumbsStore = getBreadCrumbsStore();

    watch(
      () => props.openModal,
      isOpen => {
        if (isOpen) {
          breadcrumbsStore.addBreadcrumb({
            type: 'modal',
            closeCb() {
              emit('close');
            }
          });
        } else {
          breadcrumbsStore.removeLastBreadcrumb();
        }
      }
    );
    return {};
  }
});
</script>

<style scoped lang="scss"></style>
