<template>
  <div class="pager">
    <div class="pager__nav-wrapper">
      <button
        class="pager__nav"
        :class="{ disabled: currentPage === 1 }"
        @click="handlePageChange(currentPage - 1)"
      >
        <SvgIcon
          v-if="!isMobile"
          icon="ic-arr-left"
          icon_width="14"
          icon_height="12"
          color="black"
        />
        <SvgIcon v-else icon="ic-arrow-left" icon_width="14" icon_height="12" color="black" />

        <span class="move-text">{{ _('Previous') }}</span>
      </button>
    </div>

    <div class="pager__list">
      <button
        v-for="index in paginationItems"
        :key="index"
        class="pager__item"
        :class="{ active: index === currentPage, ellipsis: index === '...' }"
        @click.prevent="index !== '...' && $emit('change', index)"
      >
        {{ index }}
      </button>
    </div>

    <div class="pager__nav-wrapper">
      <button
        class="pager__nav"
        :class="{ disabled: currentPage === totalPages }"
        @click="handlePageChange(currentPage + 1)"
      >
        <span class="move-text">{{ _('Next') }}</span>
        <SvgIcon
          v-if="!isMobile"
          icon="ic-arr-right"
          icon_width="14"
          icon_height="12"
          color="black"
        />
        <SvgIcon v-else icon="ic-arrow-right" icon_width="14" icon_height="12" color="#4154ef" />
      </button>
    </div>
    <div class="pagination-controls">
      <div class="counter">
        {{ _('Showing') }} {{ (currentPage - 1) * recordsPerPage + 1 }} -
        {{ Math.min(currentPage * recordsPerPage, totalDocs) }} {{ _('of') }}
        <span>{{ totalDocs }}</span>
        {{ _('records') }}
      </div>

      <div class="records-per-page">
        <MySelect
          :open-upwards="true"
          :width="'100%'"
          :gap="'5px'"
          :value="recordsPerPage.toString()"
          :select-list="recordsPerPageOptions"
          :empty-text="_('Per page')"
          @update:value="updateRecordsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { _ } from '@/common/language/translator';
import { computed, defineComponent, ref } from 'vue';
import MySelect from '@/components/UI/MySelect.vue';
import { useUIStore } from '@/store/modules/UI_store';
import SvgIcon from '@/components/UI/SvgIcon.vue';

export default defineComponent({
  name: 'Pagination',
  components: { SvgIcon, MySelect },
  props: {
    totalPages: {
      type: Number,
      required: false,
      default: 0
    },
    totalDocs: {
      type: Number,
      required: false,
      default: 0
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    dataType: {
      type: String as () => 'user' | 'campaign' | 'lead' | 'invited' | 'call',
      required: true
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const uiStore = useUIStore();
    const recordsPerPage = computed(() => uiStore.getRecordsPerPage(props.dataType));
    const isMobile = computed(() => uiStore.isMobile);

    const recordsPerPageOptions = ref<string[]>(['10', '20', '50', '100']);

    function updateRecordsPerPage(newPerPage: string): void {
      const newPerPageNumber = parseInt(newPerPage, 10);
      if (!isNaN(newPerPageNumber)) {
        uiStore.setRecordsPerPage(props.dataType, newPerPageNumber);
      }
    }

    function handlePageChange(page: number): void {
      if (page > 0 && page <= props.totalPages) {
        emit('change', page);
      }
    }

    const paginationItems = computed(() => {
      const total = props.totalPages;
      const current = props.currentPage;
      const maxVisible = 3;

      if (total <= maxVisible * 2 + 6) {
        return Array.from({ length: total }, (_, i) => i + 1);
      }

      const leftItems = Array.from({ length: maxVisible }, (_, i) => i + 1);
      const rightItems = Array.from({ length: maxVisible }, (_, i) => total - maxVisible + i + 1);
      const middleItems = Array.from(
        { length: maxVisible },
        (_, i) => current - Math.floor(maxVisible / 2) + i
      ).filter(page => page > maxVisible && page <= total - maxVisible);

      const uniqueItems = [...new Set([...leftItems, ...middleItems, ...rightItems])];
      uniqueItems.sort((a, b) => a - b);

      const withEllipsis = [];
      let prevPage = 0;

      for (const page of uniqueItems) {
        if (page - prevPage > 1) {
          withEllipsis.push('...');
        }
        withEllipsis.push(page);
        prevPage = page;
      }

      return withEllipsis;
    });

    return {
      isMobile,
      paginationItems,
      recordsPerPage,
      recordsPerPageOptions,
      handlePageChange,
      updateRecordsPerPage,
      _
    };
  }
});
</script>

<style scoped></style>
