import { RouteRecordRaw } from 'vue-router';

const Dashboard = () => import('@/pages/Dashboard.vue');
const Typography = () => import('@/pages/Typography.vue');
const NotFound = () => import('@/pages/NotFound.vue');
const PrivacyPolicy = () => import('@/pages/PrivacyPolicy.vue');
const CreateBusiness = () => import('@/pages/CreateBusiness.vue');
const InvitationToBono = () => import('@/layouts/InvitationToBono.vue');
const InvitationToMeeting = () => import('@/layouts/InvitationToMeeting.vue');

export const mainRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/main',
    meta: { requiresAuth: true }
  },
  {
    path: '/main',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true, breadCrumbName: 'Home' }
  },
  {
    name: 'Typography',
    path: '/typography',
    component: Typography,
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*', // will match all paths
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    name: 'PrivacyPolicy'
  },
  {
    name: 'CreateBusiness',
    path: '/business/create',
    component: CreateBusiness
  },
  {
    name: 'InvitationToBono',
    path: '/invitation/:id/:token',
    component: InvitationToBono
  },
  {
    name: 'InvitationToMeeting',
    path: '/meeting/:id/:token',
    component: InvitationToMeeting
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

export default mainRoutes;
