<template>
  <div ref="wrapperRef">
    <div class="form-search-btn" @click="toggleSearch">
      <svg class="icon ic-search" width="15" height="15">
        <use xlink:href="@/assets/sprites/sprite.svg#ic-search"></use>
      </svg>
    </div>

    <form
      ref="searchRef"
      :class="['form-search', { open: isSearchOpen && isMobile }]"
      v-if="!isMobile || isSearchOpen"
    >
      <input
        ref="searchInput"
        type="text"
        name="search"
        autocomplete="off"
        :placeholder="t('Search')"
        @input="updateInput"
      />

      <button class="form-search__btn" type="submit">
        <svg class="icon ic-search" width="15" height="15">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-search"></use>
        </svg>
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import { _ as t } from '@/common/language/translator';
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import { useAuthStore } from '@/store/modules/auth';
import useClickOutside from '@/common/clickOutside';

export default defineComponent({
  name: 'MyFormSearch',
  props: {
    modelValue: [String, Number],
    isMobile: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ['update:modelValue', 'search-toggle'],
  setup(_, { emit }) {
    const authStore = useAuthStore();
    const isSearchOpen = ref(false);
    const searchRef = ref<HTMLElement | null>(null);
    const wrapperRef = ref<HTMLElement | null>(null);
    const searchInput = ref<HTMLInputElement | null>(null);

    function updateInput(e: Event): void {
      emit('update:modelValue', (e.target as HTMLInputElement).value);
    }

    onMounted(() => {
      useClickOutside(wrapperRef, () => {
        isSearchOpen.value = false;
      });
    });

    watch(isSearchOpen, newValue => {
      emit('search-toggle', newValue);
    });

    function toggleSearch(): void {
      isSearchOpen.value = !isSearchOpen.value;
      if (isSearchOpen.value) {
        nextTick(() => {
          searchInput.value?.focus();
        });
      }
    }

    return {
      updateInput,
      toggleSearch,
      isSearchOpen,
      t,
      searchInput,
      searchRef,
      authStore,
      wrapperRef
    };
  }
});
</script>

<style lang="scss">
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.form-search {
  position: relative;

  input[type='text'] {
    display: inline-flex;
    align-items: center;
    height: 4.4rem;
    width: 188px;
    font-family: $font-site;
    @include font(none, none, 1.3rem, 1, $color-text);
    padding: 0 20px 0 50px;
    border: 1px solid #f5f5f9;
    border-radius: 2.2rem;
    outline: 0;
    margin: 0;
    background: #f5f5f9;
    box-shadow: none;
    transition: border 0.2s ease 0s;
  }

  input[type='text']:focus {
    border-color: #000;
  }

  input[type='text']::placeholder {
    color: #8a919f;
    opacity: 1;
  }

  &__btn {
    background: transparent;
    border: none;
    outline: none;
    font-size: 0;
    box-shadow: none;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
}

.form-search-btn {
  display: none;
}

@media only screen and (max-width: 991px) {
  .form-search-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 6px 46px 0 rgba(196, 203, 214, 0.36);
    cursor: pointer;
  }

  .form-search {
    display: none;

    &.open {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 75px; /* Adjust based on your layout */
      left: 0;
      right: 0;
      padding: 10px;
      border-radius: 10px;
      z-index: 1000;
    }

    input[type='text'] {
      display: inline-flex;
      align-items: center;
      width: calc(100% - 40px);
      height: 4.4rem;
      font-family: $font-site;
      @include font(none, 700, 1.6rem, 1, $color-text);
      padding: 0 20px 0 50px;
      border: 1px solid #f5f5f9;
      border-radius: 2.2rem;
      outline: 0;
      margin: 0;
      background: #ffffff;
      box-shadow: 0 6px 46px 0 rgba(196, 203, 214, 0.36);
      transition: border 0.2s ease 0s;
    }

    .form-search__btn {
      align-self: flex-end;
      left: 50px;
    }
  }
}
</style>
