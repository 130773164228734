<template>
  <div
    ref="headerRef"
    :style="{ width: width }"
    class="select"
    :class="{ open: optionsToOpen, 'open-upwards': openUpwards }"
  >
    <div :style="{ fontSize: fontSize, gap: gap }" class="select__main" @click="openOptions">
      <svg v-if="icon" :class="'icon ic-' + icon" width="15" height="15">
        <use :xlink:href="require(`../../assets/sprites/sprite.svg`) + '#ic-' + icon"></use>
      </svg>
      <div class="select__text-container">
        <div v-if="selected">{{ selected.split('@@')[0] }}</div>
        <div v-else class="empty-select">{{ emptyText }}</div>
      </div>
      <div class="select__arr">
        <svg class="icon ic-arrow-down" width="11" height="6">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>

    <div
      v-show="optionsToOpen"
      :style="{ width: optionsWidth, maxHeight: optionsMaxHeight }"
      class="select__list"
    >
      <div
        v-for="(item, index) in optionList"
        :key="index"
        class="select__option"
        :class="{ active: item.index === value || item.value === value }"
        @click="changeOption(item)"
      >
        {{ item.value.split('@@')[0] }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { _ } from '@/common/language/translator';
import { defineComponent, onMounted, PropType, ref, watchEffect } from 'vue';
import { useEventBus } from '@/common/eventBus';
import useClickOutside from '@/common/clickOutside';

interface Item {
  index: number | string;
  value: string;
}

export default defineComponent({
  name: 'MySelect',
  props: {
    value: {
      type: [Object, String] as PropType<any>,
      required: true
    },
    emptyText: {
      type: String,
      required: false,
      default: _('Select')
    },
    selectList: {
      type: Array as () => string[],
      required: false
    },
    enumOptions: {
      type: Object as PropType<
        | {
            [key: string | number]: string;
          }
        | object
      >,
      required: false,
      default: () => ({})
    },
    icon: {
      type: String,
      required: false
    },
    width: {
      type: String,
      default: '100%'
    },
    optionsWidth: {
      type: String,
      default: '100%'
    },
    optionsMaxHeight: {
      type: String,
      default: '230px'
    },
    fontSize: {
      type: String,
      default: '1rem'
    },
    gap: {
      type: String,
      default: '38px'
    },
    openUpwards: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { state, setOpenSelectId } = useEventBus();
    const optionsToOpen = ref(false);
    const optionList: Item[] = props.selectList
      ? props.selectList.map((value: string) => ({ value, index: value }))
      : Object.entries(props.enumOptions ?? {}).map(([index, value]) => ({
          index,
          value
        }));
    const selected = ref(
      optionList.find(item => item.index === props.value || item.value === props.value)?.value
    );
    const headerRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      useClickOutside(headerRef, () => {
        optionsToOpen.value = false;
      });
    });

    const changeOption = (item: Item) => {
      selected.value = item.value;
      emit('update:value', item.index);
      optionsToOpen.value = false;
    };

    const openOptions = () => {
      optionsToOpen.value = !optionsToOpen.value;
      if (optionsToOpen.value) {
        setOpenSelectId(props.value);
      } else {
        setOpenSelectId(null);
      }
    };

    // Automatically close this select if another select is opened
    watchEffect(() => {
      if (state.openSelectId !== props.value && optionsToOpen.value) {
        optionsToOpen.value = false;
      }
    });

    return {
      headerRef,
      optionsToOpen,
      optionList,
      selected,
      changeOption,
      openOptions
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';

.select {
  position: relative;

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //gap: 38px;
    height: 4.4rem;
    border-radius: 2.2rem;
    border: 1px solid #f5f5f9;
    padding: 5px 20px;
    cursor: pointer;
    transition: background 0.2s ease 0s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Poppins', Arial, sans-serif;

    .select__text-container {
      flex-grow: 1; // Allows the container to grow and fill the space
      padding-right: 10px; // Give some space for the arrow
      overflow: hidden; // Hide overflow text
      font-size: 14px;
    }

    .select__text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; // Add ellipsis to overflow text
      font-family: inherit;
    }

    .empty-select {
      font-weight: 500;
      font-family: inherit;
      font-size: 1.3rem;
    }

    .icon {
      fill: #000;
      flex-shrink: 0;
    }

    &:hover {
      background: #f5f5f9;
    }
  }

  &__arr {
    transition: transform 0.2s ease 0s;

    .icon {
      fill: rgba(0, 0, 0, 0.2);
    }
  }

  &__list {
    display: none;
    padding: 10px 15px;
    max-height: 230px;
    border: 1px solid #f5f5f9;
    border-radius: 15px;
    background: #fff;
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    right: 0;
    overflow-y: auto;
    width: auto;
    z-index: 1000;
    font-family: 'Poppins', Arial, sans-serif;
  }

  &__option:not(:last-child) {
    margin-bottom: 18px;
  }

  &__option {
    @include font(none, none, 1.3rem, 1.6, $color-text);
    transition: all 0.2s ease 0s;
    cursor: pointer;
    font-family: 'Poppins', Arial, sans-serif;

    &.active,
    &:hover {
      color: #8a919f;
    }
  }

  &.open &__btn {
    background: #f5f5f9;
  }

  &.open &__arr {
    transform: rotate(180deg);
  }

  &.open &__list {
    display: inline-block;
  }

  &.open-upwards &__list {
    top: auto;
    bottom: calc(100% + 3px);
  }
}

html.rtl {
  .select {
    &__option {
      direction: rtl;
      text-align: right;
    }

    &__list {
      width: auto;
      left: 0;
      right: 0;
    }

    &__field {
      justify-content: flex-start; // Change to flex-start for RTL
    }

    &__popup {
      left: unset;
      right: 0;
    }
  }
}
</style>
