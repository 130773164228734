<template>
  <div class="choose-acc" :class="{ open: openPopup }" ref="headerRef">
    <div class="choose-acc__top" @click="openPopup = !openPopup">
      <div
        class="choose-acc__img"
        :style="
          currentBusiness?.image
            ? { 'background-image': `url(${currentBusiness?.image})` }
            : { 'background-color': '#F5F5F9' }
        "
      ></div>

      <div class="choose-acc__text">{{ currentBusiness?.name ?? 'Select Business' }}</div>
      <div class="choose-acc__arr">
        <svg class="icon ic-arrow-down" width="11" height="6">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>

    <div class="choose-acc-popup">
      <div class="choose-acc-popup__search">
        <input type="text" :placeholder="_('Search')" v-model="searchQuery" />
        <svg class="icon ic-search" width="15" height="15">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-search"></use>
        </svg>
      </div>

      <div
        class="choose-acc-popup__item"
        v-for="item in filteredBusiness"
        :key="item._id"
        @click="changeOption(item._id)"
        :class="{ active: item._id === currentBusiness?._id }"
      >
        <div class="choose-acc-popup__content">
          <div
            class="choose-acc-popup__img"
            v-if="item?.image"
            :style="{ 'background-image': `url(${item.image!})` }"
          ></div>

          <div class="choose-acc-popup__info">
            <div class="choose-acc-popup__title">{{ item.name }}</div>
            <div class="choose-acc-popup__mail">{{ item.email }}</div>
          </div>
        </div>

        <div class="choose-acc-popup__check" v-if="item._id == currentBusiness?._id">
          <svg class="icon ic-tick" width="8" height="6">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-tick"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { _ } from '@/common/language/translator';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { getAuthStore } from '@/store';
import { IBusiness } from '@/types/interfaces';
import useClickOutside from '@/common/clickOutside';

export default defineComponent({
  name: 'SwitchAcc',
  setup() {
    const authStore = getAuthStore();
    const businesses = computed(() => authStore.businesses);
    const currentBusiness = computed(() => authStore.currentBusiness);
    const openPopup = ref(false);
    const searchQuery = ref('');
    const headerRef = ref<HTMLElement | null>(null);
    const versionKey = ref('v1.0');

    const preloadImages = () => {
      businesses.value.forEach((business: IBusiness) => {
        if (business.image) {
          const img = new Image();
          img.src = `${business.image}`;
        }
      });
    };

    onMounted(() => {
      useClickOutside(headerRef, () => (openPopup.value = false));
      preloadImages(); // Preload images on component mount
    });

    const changeOption = async (businessId?: string) => {
      try {
        const selectedBusiness = authStore.businesses.find(
          (business: IBusiness) => business._id === businessId
        );
        if (selectedBusiness) {
          await authStore.setCurrentBusiness(selectedBusiness);
        }
      } catch (error) {
        console.error('Error fetching business:', error);
      }
    };

    const filteredBusiness = computed(() => {
      if (searchQuery.value.trim() === '') {
        return authStore.businesses.slice(0, 4);
      }
      return authStore.businesses.filter(campaign =>
        campaign.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const searchOptions = computed(() => {
      let otherBusinesses = businesses.value.filter(
        (business: IBusiness) => business._id !== authStore.currentBusiness?._id
      );
      if (authStore.currentBusiness) {
        return [authStore.currentBusiness, ...otherBusinesses];
      } else {
        return otherBusinesses;
      }
    });

    return {
      versionKey,
      businesses,
      currentBusiness,
      openPopup,
      searchQuery,
      searchOptions,
      filteredBusiness,
      headerRef,
      changeOption,
      _
    };
  }
});
</script>

<style scoped></style>
