<template>
  <MissingName />
  <PaymentRequired />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import PaymentRequired from '@/components/Popups/PaymentRequired.vue';
import MissingName from '@/components/Popups/MissingName.vue';

export default defineComponent({
  name: 'Switcher',
  components: {
    PaymentRequired,
    MissingName
  }
});
</script>
