<template>
  <ul class="tabs-page__list">
    <li
      v-for="tab in tabs"
      :key="tab.id"
      :class="['tabs-page__item', { active: tab.id === modelValue }]"
      @click="setActiveTab(tab.id)"
    >
      {{ tab.label }}
      <span v-if="tab.count" :class="['tabs-page__count', tab.countClass]">
        {{ tab.count }}
      </span>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { NotificationTabHeader } from '@/components/Notifications/Notifications.vue';

export default defineComponent({
  props: {
    tabs: {
      type: Array as () => NotificationTabHeader[],
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const setActiveTab = (tabId: string) => {
      emit('update:modelValue', tabId);
    };

    return {
      setActiveTab
    };
  }
});
</script>

<style scoped></style>
