import { RouteRecordRaw } from 'vue-router';

const UserProfile = () => import('@/pages/User/UserProfile.vue');
const UserSettingNotifications = () => import('@/pages/User/UserSettingNotifications.vue');

export const userRoutes: RouteRecordRaw[] = [
  {
    path: 'settings/profile',
    component: UserProfile,
    meta: { requiresAuth: true }
  },
  {
    path: 'settings/shifts',
    component: UserProfile,
    meta: { requiresAuth: true }
  },
  {
    name: 'UserSettingNotifications',
    path: 'settings/notifications',
    component: UserSettingNotifications,
    meta: { requiresAuth: true }
  }
];

export default userRoutes;
