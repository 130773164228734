import MyButton from './MyButton';
import MyButtonCircle from './MyButtonCircle';
import MyCheckbox from './MyCheckbox';
import MySwitch from './MySwitch';
import MyPager from './Pagination.vue';
import ChooseInput from './ChooseInput';
import MySelect from './MySelect';
import MyFormSearch from './MyFormSearch';

export default [
  MyButton,
  MyButtonCircle,
  MyCheckbox,
  MySwitch,
  MyPager,
  ChooseInput,
  MySelect,
  MyFormSearch
];
