<template>
  <Mob_Modal :openModal="openNotice" @close="onClose">
    <div v-if="!isMobileView" class="modal__top">
      <my-button-circle
        class="btn-circle_grey"
        :icon="'close'"
        :icon-width="'10'"
        :icon-height="'10'"
        @click="onClose"
      />
    </div>

    <div class="modal__content">
      <div class="notice-info">
        <div class="notice-info__top">
          <div class="notice-info__title">{{ t('Notifications') }}</div>

          <div class="notice-info__status notice-info__status_green" @click="handleMarkAllAsRead">
            <svg class="icon ic-check" width="17" height="9">
              <use xlink:href="@/assets/sprites/sprite.svg#ic-check"></use>
            </svg>
            {{ t('Mark all as read') }}
          </div>
        </div>

        <div class="tabs-page tabs-page_line">
          <TabHeader v-model="activeTab" :tabs="tabs" />

          <TabContent v-if="activeTab === 'all'" :is-active="true" />
          <TabContent
            v-else-if="activeTab === 'unread'"
            :read-filter="false"
            :is-active="true"
            @refresh-count="fetchNotificationsCount"
          />
          <TabContent
            v-else-if="activeTab === 'read'"
            :read-filter="true"
            :is-active="true"
            @refresh-count="fetchNotificationsCount"
          />
        </div>
      </div>
    </div>
  </Mob_Modal>
</template>
<script lang="ts">
import { _ as t } from '@/common/language/translator';
import { computed, defineComponent, onMounted, ref } from 'vue';
import MyButtonCircle from '../UI/MyButtonCircle.vue';
import TabHeader from '@/components/Notifications/TabHeader.vue';
import TabContent from '@/components/Notifications/TabContent.vue';
import axios from '@/services/axios';
import { User_URL } from '@/store/modules/auth';
import { useNotificationStore } from '@/store/modules/notification_store';
import Mob_Modal from '@/mobile_components/Mob_Modal.vue';
import { useUIStore } from '@/store/modules/UI_store';

export interface NotificationTabHeader {
  id: string;
  label: string;
  count: number;
  countClass?: string;
}

interface NotificationsCountResponse {
  success: boolean;
  result: {
    read: number;
    unread: number;
  };
}

export default defineComponent({
  name: 'Notifications',
  components: { Mob_Modal, TabContent, TabHeader, MyButtonCircle },
  props: {
    openNotice: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['close', 'allNotificationsRead'],
  setup(_, { emit }) {
    const notificationStore = useNotificationStore();
    const uiStore = useUIStore();
    const isMobileView = computed(() => uiStore.isMobile);
    const activeTab = ref('all');
    const readCount = computed(() => notificationStore.getReadCount);
    const unreadCount = computed(() => notificationStore.getUnreadCount);
    const allCount = computed(() => notificationStore.getAllCount);

    const tabs = computed<NotificationTabHeader[]>(() => [
      {
        id: 'all',
        label: t('All'),
        count: allCount.value,
        countClass: 'tabs-page__count_red'
      },
      {
        id: 'unread',
        label: t('Unread'),
        count: unreadCount.value
      },
      {
        id: 'read',
        label: t('Read'),
        count: readCount.value
      }
    ]);

    const fetchNotificationsCount = async () => {
      try {
        const response = await axios.get<NotificationsCountResponse>(
          User_URL + 'notifications/count'
        );
        if (response.data.success) {
          useNotificationStore().setReadCount(response.data.result.read);
          useNotificationStore().setUnreadCount(response.data.result.unread);
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };
    onMounted(async () => {
      await fetchNotificationsCount();
      // todo: change time interval to what product wants
      setInterval(fetchNotificationsCount, 600000);
    });

    const handleMarkAllAsRead = async () => {
      await notificationStore.markAllAsRead();
    };

    const onClose = () => {
      activeTab.value = 'all';
      emit('close');
    };

    return {
      isMobileView,
      tabs,
      activeTab,
      onClose,
      handleMarkAllAsRead,
      fetchNotificationsCount,
      t
    };
  }
});
</script>

<style scoped lang="scss"></style>
