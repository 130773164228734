import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetifyPlugin = createVuetify({
  components,
  directives
});

export default vuetifyPlugin;
