import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tabs-page__list" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("li", {
        key: tab.id,
        class: _normalizeClass(['tabs-page__item', { active: tab.id === _ctx.modelValue }]),
        onClick: ($event: any) => (_ctx.setActiveTab(tab.id))
      }, [
        _createTextVNode(_toDisplayString(tab.label) + " ", 1),
        (tab.count)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(['tabs-page__count', tab.countClass])
            }, _toDisplayString(tab.count), 3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}