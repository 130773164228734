import { defineStore } from 'pinia';
import { MOBILE_VIEW_THRESHOLD } from '@/common/lib/constant';

export type LeadView = 'table' | 'cards';

interface TableSettings {
  [key: string]: {
    recordsPerPage: number;
  };
}

interface UIState {
  leadView: LeadView;
  tableSettings: TableSettings;
  loadingStates: Record<string, boolean>;
  mainBarOpen: boolean;
  mobileMainBarOpen: boolean;
  modalState: Record<string, boolean>;
  isMobile: boolean;
  openMediaPlayer: boolean;
}

export const useUIStore = defineStore('ui', {
  state: (): UIState => ({
    leadView: 'table',
    tableSettings: {
      campaign: { recordsPerPage: 10 },
      lead: { recordsPerPage: 10 },
      user: { recordsPerPage: 20 },
      call: { recordsPerPage: 10 },
      invited: { recordsPerPage: 10 }
    },
    loadingStates: {},
    mainBarOpen: true,
    mobileMainBarOpen: true,
    modalState: {},
    isMobile: window.innerWidth <= MOBILE_VIEW_THRESHOLD,
    openMediaPlayer: false
  }),
  getters: {
    getLeadView: (state: UIState): LeadView => state.leadView,
    getRecordsPerPage:
      state =>
      (tableType: string): number => {
        return state.tableSettings[tableType]?.recordsPerPage;
      },
    isLoading:
      state =>
      (key: string): boolean => {
        return state.loadingStates[key] || false;
      },
    isMobileOn: (state: UIState): boolean => state.isMobile,
    isMainBarOpen: state => state.mainBarOpen,
    isMobileSideBarOpen: state => state.mobileMainBarOpen,
    isModalOpen:
      state =>
      (key: string): boolean => {
        return state.modalState[key] || false;
      },
    isMediaPlayerOpen: state => state.openMediaPlayer
  },
  actions: {
    reset() {
      this.$reset();
    },
    setLeadView(view: LeadView) {
      this.leadView = view;
    },
    setRecordsPerPage(tableType: string, recordsPerPage: number) {
      // Corrected action
      if (this.tableSettings[tableType]) {
        this.tableSettings[tableType].recordsPerPage = recordsPerPage;
      }
    },
    setLoadingState(key: string, value: boolean) {
      this.loadingStates[key] = value;
    },
    toggleSideBar() {
      this.mainBarOpen = !this.mainBarOpen;
    },
    toggleMobileSideBar(value?: boolean) {
      this.mobileMainBarOpen = value ?? !this.mobileMainBarOpen;
    },
    toggleModal(key: string) {
      this.modalState[key] = !this.modalState[key];
    },
    toggleMediaPlayer(value: boolean) {
      this.$patch({ openMediaPlayer: value });
    },
    updateViewport(value: boolean) {
      this.$patch({ isMobile: value });
    }
  },
  persist: true
});
