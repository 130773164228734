import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d75ee45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_button_circle = _resolveComponent("my-button-circle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "slide",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationStore.messages, (msg) => {
          return (_openBlock(), _createElementBlock("div", {
            key: msg.id,
            class: "notification"
          }, [
            _createTextVNode(_toDisplayString(msg.message) + " ", 1),
            _createVNode(_component_my_button_circle, {
              class: "btn-circle_grey close-btn",
              icon: 'close',
              "icon-width": '10',
              "icon-height": '10',
              onClick: ($event: any) => (_ctx.removeMessage(msg.id))
            }, null, 8, ["onClick"])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}