<template>
  <div class="notification-wrapper">
    <TransitionGroup name="slide" tag="div">
      <div v-for="msg of notificationStore.messages" :key="msg.id" class="notification">
        {{ msg.message }}
        <my-button-circle
          class="btn-circle_grey close-btn"
          :icon="'close'"
          :icon-width="'10'"
          :icon-height="'10'"
          @click="removeMessage(msg.id)"
        />
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
import { getNotificationStore } from '@/store';
import MyButtonCircle from '@/components/UI/MyButtonCircle.vue';

export default defineComponent({
  name: 'SmallNotification',
  components: { MyButtonCircle },
  props: {
    message: String
  },
  setup() {
    const show = ref(true);
    const notificationStore = getNotificationStore();

    watchEffect(() => {
      setTimeout(() => {
        show.value = false;
      }, 5000);
    });

    function removeMessage(id: number) {
      notificationStore.removeMessage(id);
    }

    return {
      show,
      notificationStore,
      removeMessage
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.notification-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  padding: 20px;
}

.notification {
  background-color: $color-grey;
  color: white;
  @include font(none, 500, 1.3rem, none, #000);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.3s;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  padding: 5px;
  margin-left: 5px;
  order: 2;
  height: 20px;
  width: 20px;
  line-height: 1;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon.ic-close {
  width: 10px;
  height: 10px;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
