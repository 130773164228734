import {
  Filter,
  IBusiness,
  IUser,
  USER_ROLES,
  UserBusiness,
  UserRoleLevels
} from '@/types/interfaces';
import { defineStore } from 'pinia';
import axios from '@/services/axios';
import instance from '@/services/axios';
import { useCampaignStore } from '@/store/modules/campaign_store';
import { useLeadStore } from '@/store/modules/lead_store';
import { resetAllStores } from '@/store';
import { ApiBusinessUserResponse, ApiLoginResponse } from '@/types/api_interface';
import { Languages, setLanguage } from '@/common/language/translator';
import { refreshFn } from '@/common/lib/refresh';
import router from '@//router/router';
import { AxiosError } from 'axios';
import { useNotificationStore } from '@/store/modules/notification_store';
import { useUIStore } from '@/store/modules/UI_store';

export const API_URL = '/auth/';
export const User_URL = '/user/';
export const Lead_URL = '/lead/';
export const Business_URL = '/user/business/';
export const Campaign_URL = '/user/campaign/';
export const Integration_URL = '/user/integrations/';
export const Dashboard_URL = '/dashboard/';

interface AuthState {
  businesses: IBusiness[];
  currentBusiness: IBusiness | null;
  currentUser: IUser | null;
  isLoggedIn: boolean;
  loading: boolean;
  isOwner: boolean;
  imageUpdateKey: number;
  selectedLanguage: Languages | null;
}

export const useAuthStore = defineStore({
  id: 'auth-store',
  state: (): AuthState => ({
    businesses: [] as IBusiness[],
    currentBusiness: null as IBusiness | null,
    currentUser: {} as IUser,
    isLoggedIn: false,
    loading: false,
    isOwner: false,
    imageUpdateKey: Date.now(),
    selectedLanguage: null
  }),
  getters: {
    getCurrentUser: state => (state.isLoggedIn ? state.currentUser : null),
    getIsLoggedIn: state => state.isLoggedIn,
    getLoading: state => state.loading,
    getBusinesses: state => state.businesses,
    getCurrentBusiness: state => state.currentBusiness,
    getIsOwner: state => state.isOwner
  },
  actions: {
    async login(user: Partial<IUser>): Promise<ApiLoginResponse | null> {
      try {
        this.setLoadingState(true);
        const response = await axios.post<ApiLoginResponse>(API_URL + 'login', user);

        if (response.data.success) {
          localStorage.setItem('token', response.data.accessToken);
          await this.fetchCurrentUser();
        } else {
          console.error('Login failed:', response.data.message);
        }

        return response.data; // Return the response data to display messages
      } catch (error: unknown) {
        if (error instanceof AxiosError && error.response) {
          const message = error.response.data?.message || 'An unexpected server error occurred';
          console.error('An error occurred while logging in the user:', message);
        } else {
          console.error('An error occurred while logging in the user:', error);
        }
        return null;
      } finally {
        this.setLoadingState(false);
      }
    },

    async postLoginActions(token: string) {
      localStorage.setItem('token', token);
      await this.fetchCurrentUser();
    },

    logout(): void {
      try {
        this.setLoadingState(true);
        localStorage.clear();
        resetAllStores([
          useCampaignStore(),
          useLeadStore(),
          useNotificationStore(),
          useUIStore(),
          this
        ]); // add more stores here in the future
      } catch (error) {
        console.error('An error occurred while logging out the user:', error);
        throw new Error('Failed to log out user');
      } finally {
        this.setLoadingState(false);
      }
    },

    async register(user: Partial<IUser>) {
      try {
        this.setLoadingState(true);
        const response = await axios.post(API_URL + 'register', user);
        if (response.data.accessToken) {
          localStorage.setItem('token', response.data.accessToken);
          this.setCurrentUser(response.data.user);
          return { success: true, message: 'Registration successful.' };
        }
        return { success: false, message: response.data.message };
      } catch (error: any) {
        console.error('An error occurred while registering the user:', error);
        if (error.response && error.response.status === 401) {
          return { success: false, message: error.response.data.message };
        } else {
          return { success: false, message: 'An unexpected error occurred.' };
        }
      } finally {
        this.setLoadingState(false);
      }
    },

    async fetchCurrentUser() {
      const campaignStore = useCampaignStore();

      try {
        this.setLoadingState(true);
        const response = await instance.get(`${API_URL}getCurrentUser`);
        if (response.data.success) {
          this.setCurrentUser(response.data.user);
          if (response.data.user.userLanguage != null) {
            setLanguage(response.data.user.userLanguage);
          }
          const businessData: IBusiness[] = response.data.businesses.map(
            (business: UserBusiness) =>
              ({
                ...business.business,
                _id: business.businessId,
                role: business.role
              }) as IBusiness
          );
          if (businessData.length > 0) {
            this.setBusinesses(businessData);
            const url = document.location.pathname;
            const regex = /\/b\/([a-zA-Z0-9]+)/;
            const currentBusiness = url.match(regex)?.[1];
            let selectedBusiness = businessData[0];
            if (currentBusiness) {
              const business = businessData.find(business => business._id === currentBusiness);
              if (business) {
                selectedBusiness = business;
              }
            }
            await this.setCurrentBusiness(selectedBusiness);
            await campaignStore.fetchCampaigns();
          } else {
            console.log('No businesses found for the user');
          }
          this.setLoggedInState(true);
          return response.data;
        }
      } catch (error) {
        console.error('An error occurred while fetching the current user:', error);
      } finally {
        this.setLoadingState(false);
      }
      throw new Error('Network error while fetching the current user.');
    },

    // TODO: Delete this if no needed
    // async fetchBusiness(businessId: string): Promise<IBusiness | null> {
    //   try {
    //     const response = await axios.get(Business_URL + 'info', {
    //       params: { businessId }
    //     });
    //     if (response.data.success) {
    //       await this.setCurrentBusiness(response.data.business);
    //       return response.data.business;
    //     } else {
    //       console.warn('Business fetch unsuccessful. Server responded with:', response.data);
    //       return null;
    //     }
    //   } catch (error: any) {
    //     // Log server error messages if available, otherwise log the error object
    //     if (error.response && error.response.data && error.response.data.error) {
    //       console.error(
    //         'An error occurred while fetching the business:',
    //         error.response.data.error
    //       );
    //     } else {
    //       console.error('An error occurred while fetching the business:', error);
    //     }
    //     return null;
    //   }
    // },

    async fetchUsersForBusiness(page = 1, limit = 10, filters: Filter[] = [], search = '') {
      let apiResponse: ApiBusinessUserResponse | null = null;
      try {
        const params = new URLSearchParams({
          page: String(page),
          limit: String(limit),
          filters: encodeURIComponent(JSON.stringify(filters)),
          search
        });
        const response = await axios.get<ApiBusinessUserResponse>(API_URL + 'users', {
          params
        });
        apiResponse = response.data;
      } catch (error) {
        console.error('An error occurred while fetching the users:', error);
      }
      // Exit early if apiResponse is null or success is false
      if (!apiResponse || !apiResponse.success) {
        console.error(
          'Failed to fetch users: Either the request failed, or the server responded with success=false'
        );
        return null;
      }
      const { users, pagination } = apiResponse.response;
      return { users, pagination };
    },

    checkPermission(minimumRole: USER_ROLES) {
      if (this.currentBusiness) {
        const userRoleLevel = UserRoleLevels[this.currentBusiness.role];
        const minimumRoleLevel = UserRoleLevels[minimumRole];
        return userRoleLevel <= minimumRoleLevel;
      }
      return true;
    },

    reset() {
      this.$reset();
    },
    setLoadingState(loading: boolean) {
      this.$patch({ loading });
    },

    setLoggedInState(isLoggedIn: boolean) {
      this.$patch({ isLoggedIn });
    },
    setBusinesses(businesses: IBusiness[]) {
      this.$patch({ businesses });
    },
    setCurrentUser(user: IUser | null) {
      this.$patch({ currentUser: user });
      setLanguage(user?.userLanguage as any);
    },
    async setCurrentBusiness(business: IBusiness) {
      const url = router.currentRoute.value.path;
      const regex = /\/b\/[a-zA-Z0-9]+/;
      const newUrl = url.replace(regex, `/b/${business._id}`);
      if (url !== newUrl) {
        return await router.push(newUrl);
      }

      this.$patch({ currentBusiness: business });
      localStorage.setItem('businessId', business._id);
      sessionStorage.clear();
      (refreshFn as any)?.();
    },
    setSelectedLanguage(lang: Languages) {
      this.$patch({ selectedLanguage: lang });
    }
  },
  persist: false
});
