import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useLeadStore } from '@/store/modules/lead_store';
import { getAuthStore } from '@/store';
import { USER_ROLES, UserRoleLevels } from '@/types/interfaces';
import { computed } from 'vue';
import userRoutes from '@/router/userRoutes';
import businessRoutes from '@/router/businessRoutes';
import authRoutes from '@/router/authRoutes';
import mainRoutes from '@/router/mainRoutes';

const BusinessComponent = () => import('@/components/Business/BusinessComponent.vue');

const routes: RouteRecordRaw[] = [
  ...mainRoutes,
  { path: '/user', children: userRoutes },
  {
    path: '/b/:businessId',
    component: BusinessComponent,
    children: businessRoutes,
    meta: { breadCrumbName: 'Business', parent: 'Dashboard' }
  },
  ...authRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
});

router.beforeEach(async (to, from, next) => {
  const authStore = getAuthStore();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const businessRequired: USER_ROLES | null = (to.matched as any[]).reduce(
    (role, record) => {
      return record.meta.businessRequired &&
        (role === null ||
          UserRoleLevels[role as USER_ROLES] <
            UserRoleLevels[record.meta.businessRequired as USER_ROLES])
        ? record.meta.businessRequired
        : role;
    },
    null as USER_ROLES | null
  );

  if (requiresAuth || businessRequired) {
    const isLoading = computed(() => authStore.loading);
    const isLoggedIn = computed(() => authStore.isLoggedIn);

    while (isLoading.value) {
      await new Promise(resolve => setTimeout(resolve, 50));
    }

    if (!isLoggedIn.value && localStorage.getItem('token')) {
      await authStore.fetchCurrentUser().catch(() => {
        if (to.path !== '/auth/register' && to.path !== '/auth/login') {
          next('/auth/login');
          return;
        }
      });
    }

    if (!isLoggedIn.value && to.path !== '/auth/register' && to.path !== '/auth/login') {
      next('/auth/login');
      return;
    }
  }

  if (businessRequired) {
    const business = computed(() => authStore.currentBusiness);
    if (!business.value || UserRoleLevels[business.value.role] > UserRoleLevels[businessRequired]) {
      next('/main');
      return;
    }
  }
  if (from.path === '/leads') {
    const leadStore = useLeadStore();
    leadStore.selectAll = false;
    leadStore.selectedLeads = [];
  }
  next();
});

export default router;
