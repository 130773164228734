import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pager" }
const _hoisted_2 = { class: "pager__nav-wrapper" }
const _hoisted_3 = { class: "move-text" }
const _hoisted_4 = { class: "pager__list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "pager__nav-wrapper" }
const _hoisted_7 = { class: "move-text" }
const _hoisted_8 = { class: "pagination-controls" }
const _hoisted_9 = { class: "counter" }
const _hoisted_10 = { class: "records-per-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_MySelect = _resolveComponent("MySelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: _normalizeClass(["pager__nav", { disabled: _ctx.currentPage === 1 }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePageChange(_ctx.currentPage - 1)))
      }, [
        (!_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_SvgIcon, {
              key: 0,
              icon: "ic-arr-left",
              icon_width: "14",
              icon_height: "12",
              color: "black"
            }))
          : (_openBlock(), _createBlock(_component_SvgIcon, {
              key: 1,
              icon: "ic-arrow-left",
              icon_width: "14",
              icon_height: "12",
              color: "black"
            })),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx._('Previous')), 1)
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationItems, (index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: index,
          class: _normalizeClass(["pager__item", { active: index === _ctx.currentPage, ellipsis: index === '...' }]),
          onClick: _withModifiers(($event: any) => (index !== '...' && _ctx.$emit('change', index)), ["prevent"])
        }, _toDisplayString(index), 11, _hoisted_5))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: _normalizeClass(["pager__nav", { disabled: _ctx.currentPage === _ctx.totalPages }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlePageChange(_ctx.currentPage + 1)))
      }, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx._('Next')), 1),
        (!_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_SvgIcon, {
              key: 0,
              icon: "ic-arr-right",
              icon_width: "14",
              icon_height: "12",
              color: "black"
            }))
          : (_openBlock(), _createBlock(_component_SvgIcon, {
              key: 1,
              icon: "ic-arrow-right",
              icon_width: "14",
              icon_height: "12",
              color: "#4154ef"
            }))
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createTextVNode(_toDisplayString(_ctx._('Showing')) + " " + _toDisplayString((_ctx.currentPage - 1) * _ctx.recordsPerPage + 1) + " - " + _toDisplayString(Math.min(_ctx.currentPage * _ctx.recordsPerPage, _ctx.totalDocs)) + " " + _toDisplayString(_ctx._('of')) + " ", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.totalDocs), 1),
        _createTextVNode(" " + _toDisplayString(_ctx._('records')), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_MySelect, {
          "open-upwards": true,
          width: '100%',
          gap: '5px',
          value: _ctx.recordsPerPage.toString(),
          "select-list": _ctx.recordsPerPageOptions,
          "empty-text": _ctx._('Per page'),
          "onUpdate:value": _ctx.updateRecordsPerPage
        }, null, 8, ["value", "select-list", "empty-text", "onUpdate:value"])
      ])
    ])
  ]))
}