<template>
  <div ref="headerRef" class="choose-acc" :class="{ open: open }">
    <div class="choose-acc__top" @click="toggleSelectOpen">
      <div
        v-if="currentCampaign && !allLeads"
        class="choose-acc__img"
        :style="{ 'background-image': `url(${avatarUrl})` }"
      ></div>

      <div v-if="allLeads" class="choose-acc__text">
        {{ 'All campaigns' }}
      </div>
      <div v-else class="choose-acc__text">
        {{ currentCampaign?.name ?? 'Select Campaign' }}
      </div>
      <div class="choose-acc__arr">
        <svg class="icon ic-arrow-down" width="11" height="6">
          <use xlink:href="../../../assets/sprites/sprite.svg#ic-arrow-down"></use>
        </svg>
      </div>
    </div>

    <div class="choose-acc-popup">
      <div class="choose-acc-popup__search">
        <input v-model="searchQuery" type="text" placeholder="Search" />
        <svg class="icon ic-search" width="15" height="15">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-search"></use>
        </svg>
      </div>

      <div
        v-for="item in filteredCampaigns"
        :key="item._id"
        :class="[
          'choose-acc-popup__item',
          {
            active: item._id === currentCampaign?._id || (item._id === 'all' && allLeads),
            'all-option': item._id === 'all'
          }
        ]"
        @click="changeOption(item._id)"
      >
        <div class="choose-acc-popup__content">
          <div
            v-if="item.image"
            class="choose-acc-popup__img"
            :style="{ 'background-image': `url(${item.image!})` }"
          ></div>

          <div class="choose-acc-popup__info">
            <div :class="['choose-acc-popup__title', { expanded: item._id === 'all' }]">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div
          v-if="item._id == currentCampaign?._id || (item._id === 'all' && allLeads)"
          class="choose-acc-popup__check"
        >
          <svg class="icon ic-tick" width="8" height="6">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-tick"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import useClickOutside from '@/common/clickOutside';
import { useCampaignStore } from '@/store/modules/campaign_store';
import { ICampaign } from '@/types/interfaces';
import { useLeadStore } from '@/store/modules/lead_store';

export default defineComponent({
  name: 'SelectCampaign',
  props: {
    openPopup: {
      type: Boolean,
      required: true
    },
    selectAllLeadsOption: {
      type: Boolean,
      required: false
    }
  },
  emits: ['change-option', 'select-all'],
  setup(props, { emit }) {
    const leadStore = useLeadStore();
    const allLeads = computed(() => leadStore.allLeads);
    const headerRef = ref<HTMLElement | null>(null);
    const searchQuery = ref('');
    const open = ref(props.openPopup);
    const campaignStore = useCampaignStore();
    const campaigns = ref<ICampaign[]>([]);
    const currentCampaign = computed(() => campaignStore.currentCampaign!);
    const avatarUrl = computed(() =>
      currentCampaign.value?.image ? `${currentCampaign.value?.image}` : ''
    );

    onMounted(async () => {
      try {
        const fetchedCampaigns = await campaignStore.fetchCampaigns(1, 100, []);
        if (fetchedCampaigns && fetchedCampaigns.campaigns) {
          campaigns.value = fetchedCampaigns.campaigns;
          if (props.selectAllLeadsOption) {
            const allOption: ICampaign = {
              _id: 'all',
              name: 'All campaigns',
              image: '',
              links: [],
              tags: [],
              startDate: '',
              endDate: '',
              leads: [],
              totalSales: 0,
              leadsCount: 0,
              business_id: '',
              email: '',
              businessId: [],
              isEnabled: false,
              smsNotifications: [],
              emailNotifications: [],
              virtualNumber: null,
              notes: '',
              facebookCampaignId: '',
              role: undefined,
              userCount: 0
            };
            campaigns.value.unshift(allOption);
          }
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    });

    onMounted(() => {
      useClickOutside(headerRef, () => (open.value = false));
    });

    const filteredCampaigns = computed(() => {
      if (searchQuery.value.trim() === '') {
        return campaigns.value.slice(0, 4);
      }
      return campaigns.value.filter(campaign =>
        campaign.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    async function changeOption(id: string): Promise<void> {
      if (id === 'all') {
        leadStore.setAllLeads(true);
        emit('select-all', { _id: 'all', name: 'All campaigns' });
        toggleSelectOpen();
        return;
      }

      const selectedCampaign = campaigns.value.find(campaign => campaign._id === id);
      if (!selectedCampaign) {
        console.error('Campaign not found');
        return;
      }

      if (allLeads.value) {
        leadStore.setAllLeads(false);
      }

      await campaignStore.setCurrentCampaign(selectedCampaign);
      emit('change-option', selectedCampaign);
      toggleSelectOpen();
    }

    function toggleSelectOpen(): void {
      open.value = !open.value;
    }

    return {
      allLeads,
      avatarUrl,
      headerRef,
      searchQuery,
      open,
      filteredCampaigns,
      currentCampaign,
      changeOption,
      toggleSelectOpen
    };
  }
});
</script>
