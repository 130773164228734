import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c9529d74"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "fill"]
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = ["width", "height", "fill"]
const _hoisted_4 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.link)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.link,
        class: "btn-circle",
        onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTooltip = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showTooltip = false))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                class: _normalizeClass(`icon ic-${_ctx.icon}`),
                width: _ctx.iconWidth,
                height: _ctx.iconHeight,
                fill: _ctx.iconFill
              }, [
                _createElementVNode("use", {
                  "xlink:href": `${require('@/assets/sprites/sprite.svg')}#ic-${_ctx.icon}`
                }, null, 8, _hoisted_2)
              ], 10, _hoisted_1))
            : _createCommentVNode("", true),
          (_ctx.tooltip)
            ? _withDirectives((_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "tooltip"
              }, _toDisplayString(_ctx.tooltip), 513)), [
                [_vShow, _ctx.showTooltip]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "btn-circle",
        onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showTooltip = true)),
        onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showTooltip = false))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 0,
              class: _normalizeClass(`icon ic-${_ctx.icon}`),
              width: _ctx.iconWidth,
              height: _ctx.iconHeight,
              fill: _ctx.iconFill
            }, [
              _createElementVNode("use", {
                "xlink:href": `${require('@/assets/sprites/sprite.svg')}#ic-${_ctx.icon}`
              }, null, 8, _hoisted_4)
            ], 10, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.tooltip)
          ? _withDirectives((_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "tooltip"
            }, _toDisplayString(_ctx.tooltip), 513)), [
              [_vShow, _ctx.showTooltip]
            ])
          : _createCommentVNode("", true)
      ], 32))
}