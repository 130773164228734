import { useAuthStore } from '@/store/modules/auth';
import { useLeadStore } from '@/store/modules/lead_store';
import { useCampaignStore } from '@/store/modules/campaign_store';
import { useNotificationStore } from '@/store/modules/notification_store';
import { useUIStore } from '@/store/modules/UI_store';
import { useBreadcrumbStore } from '@/store/modules/breadCrumbs';

interface Resettable {
  reset(): void;
}

let authStore: ReturnType<typeof useAuthStore>;
let leadStore: ReturnType<typeof useLeadStore>;
let campaignStore: ReturnType<typeof useCampaignStore>;
let notificationStore: ReturnType<typeof useNotificationStore>;
let uiStore: ReturnType<typeof useUIStore>;
let breadcrumbStore: ReturnType<typeof useBreadcrumbStore>;

export function getNotificationStore(): ReturnType<typeof useNotificationStore> {
  if (!notificationStore) {
    notificationStore = useNotificationStore();
  }
  return notificationStore;
}

export function getAuthStore(): ReturnType<typeof useAuthStore> {
  if (!authStore) {
    authStore = useAuthStore();
  }
  return authStore;
}

export function getLeadStore(): ReturnType<typeof useLeadStore> {
  if (!leadStore) {
    leadStore = useLeadStore();
  }
  return leadStore;
}

export function getCampaignStore(): ReturnType<typeof useCampaignStore> {
  if (!campaignStore) {
    campaignStore = useCampaignStore();
  }
  return campaignStore;
}

export function getUIStore(): ReturnType<typeof useUIStore> {
  if (!uiStore) {
    uiStore = useUIStore();
  }
  return uiStore;
}

export function getBreadCrumbsStore(): ReturnType<typeof useBreadcrumbStore> {
  if (!breadcrumbStore) {
    breadcrumbStore = useBreadcrumbStore();
  }
  return breadcrumbStore;
}
export function resetAllStores(stores: Resettable[]): void {
  stores.forEach(store => store.reset());
}
