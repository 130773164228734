import { Business_URL, Campaign_URL, User_URL } from '@/store/modules/auth';
import axios from '@/services/axios';
import { getNotificationStore } from '@/store';
import { AxiosRequestConfig } from 'axios';

export const NO_AVATAR_URL = '../../assets/img/no-user-avatar.png';

export async function handleImageUpload(
  type: 'user' | 'campaign' | 'business',
  fileInput: HTMLInputElement | null,
  campaignId?: string
): Promise<string> {
  if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
    throw new Error('No file selected');
  }

  const notificationStore = getNotificationStore();

  const data = new FormData();
  data.append('avatar', fileInput.files[0]);

  let url;
  const config: AxiosRequestConfig = {};
  switch (type) {
    case 'user':
      url = User_URL + 'avatar';
      break;
    case 'campaign':
      url = Campaign_URL + 'avatar';
      if (campaignId) {
        config.params = { campaignId };
      }
      break;
    case 'business':
      url = Business_URL + 'avatar';
      break;
    default:
      throw new Error('Invalid type');
  }

  const response = await axios.post(url, data, config);
  if (response.data.success) {
    return response.data.image;
  } else {
    notificationStore.addMessage('Error updating avatar');
    throw new Error('Error updating avatar');
  }
}

export function updateBackgroundImages(className: string): void {
  // Make sure to target the correct class here
  const elements = document.querySelectorAll(`.${className}`);
  elements.forEach(element => {
    const imgSrc = element.getAttribute('data-img');
    if (imgSrc) {
      const img = new Image();
      img.onload = () => {
        // Cast element to HTMLElement to access the style property
        (element as HTMLElement).style.backgroundImage = `url('${imgSrc}')`;
        (element as HTMLElement).style.backgroundPosition = 'center';
        (element as HTMLElement).style.backgroundSize = 'cover';
      };
      img.src = imgSrc;
    }
  });
}
