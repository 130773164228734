import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6cd29d98"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "viewBox"]
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass('icon ' + _ctx.className),
    width: _ctx.icon_width,
    height: _ctx.icon_height,
    viewBox: _ctx.viewBox,
    style: _normalizeStyle({ fill: _ctx.color })
  }, [
    _createElementVNode("use", { "xlink:href": _ctx.iconPath }, null, 8, _hoisted_2)
  ], 14, _hoisted_1))
}