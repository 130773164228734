<template>
  <div class="app">
    <ActionPopUp v-if="authenticated" />
    <ThankYouPopUp :is-active="showThankYouMessage" @close="showThankYouMessage = false" />
    <div class="wrapper">
      <MainBar v-if="authenticated" />
      <SmallNotification />
      <div class="wrapper__content">
        <header-page v-if="authenticated" />
        <div class="btn-back" :class="{ hidden: !hasBack }" @click="breadcrumbStore.back()">
          <span class="btn-back__icon">
            <SvgIcon icon="ic-arr-left" width="16" height="16" color="white" />
          </span>
          Back
        </div>
        <main class="main" :class="{ main_opacity: !authenticated }">
          <Suspense>
            <router-view :key="refreshKey"></router-view>
          </Suspense>
        </main>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { getAuthStore, getBreadCrumbsStore, getNotificationStore } from '@/store';
import { setRefresh } from '@/common/lib/refresh';
import MainBar from '@/components/SideBar/MainBar.vue';
import HeaderPage from '@/components/Header/HeaderPage.vue';
import SuccessMsg from '@/components/Login/SuccessMsg.vue';
import ThankYouPopUp from '@/layouts/ThankYouPopUp.vue';
import ActionPopUp from '@/components/Popups/Switcher.vue';
import SmallNotification from '@/components/UI/SmallNotification.vue';
import { useRoute } from 'vue-router';
import SvgIcon from '@/components/UI/SvgIcon.vue';
import { useUIStore } from '@/store/modules/UI_store';

export default defineComponent({
  name: 'App',
  components: {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    SvgIcon,
    ThankYouPopUp,
    ActionPopUp,
    SuccessMsg,
    SmallNotification,
    MainBar,
    HeaderPage
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  },
  setup() {
    const breadcrumbStore = getBreadCrumbsStore();
    const route = useRoute();
    const refreshKey = ref(0);
    setRefresh(() => {
      refreshKey.value++;
    });
    const hasBack = ref(false);
    const uiStore = useUIStore();
    const isMobile = computed(() => uiStore.isMobile);
    const authStore = getAuthStore();
    const notificationStore = getNotificationStore();
    const loading = computed(() => authStore.loading);
    const authenticated = computed<boolean>(() => authStore.isLoggedIn);
    const currentUser = computed(() => authStore.currentUser);
    const showThankYouMessage = ref(false);
    const openPopUp = ref(false);

    // watcher on history.state.back
    watch(
      () => [route.fullPath, breadcrumbStore.breadcrumbs.length],
      () => {
        hasBack.value = breadcrumbStore.breadcrumbs.length > 0 || history.state.back !== null;
      },
      { immediate: true, deep: true }
    );

    return {
      hasBack,
      breadcrumbStore,
      isMobile,
      showThankYouMessage,
      currentUser,
      refreshKey,
      openPopUp,
      loading,
      authenticated,
      notificationStore
    };
  }
});
</script>

<style lang="scss">
@import '@/styles/reset.scss';
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';
@import '@/styles/fonts.scss';
@import '@/styles/main.scss';
@import '@/styles/icons.scss';
@import '@/styles/tabs-page.scss';
@import '@/styles/tag.scss';
@import '@/styles/page.scss';
@import '@/styles/table-site.scss';
@import '@/styles/modal.scss';
@import '@/styles/profile-info.scss';
@import '@/styles/form-site.scss';
@import '@/styles/main-page.scss';
@import '@/styles/view.scss';
@import '@/styles/select-check.scss';
@import '@/styles/filter.scss';
@import '@/styles/tooltip.scss';
@import '@/styles/rating.scss';
@import '@/styles/popup-actions.scss';
@import '@/styles/player.scss';
@import '@/styles/history-modal.scss';
@import '@/styles/cards.scss';
@import '@/styles/profile-card.scss';
@import '@/styles/notice.scss';
@import '@/styles/modal-main.scss';
@import '@/styles/add-block.scss';
@import '@/styles/color-edit.scss';
@import '@/styles/credit-history.scss';
@import '@/styles/file-upload.scss';
@import '@/styles/left-menu.scss';
@import '@/styles/settings.scss';
@import '@/styles/info-int.scss';
@import '@/styles/select-dropdown.scss';
@import '@/styles/filter-date.scss';
@import '@/styles/dashboard.scss';
@import '@/styles/main-bar';
@import '@/styles/header-page.scss';
@import '@/styles/header-auth.scss';
@import '@/styles/checkbox.scss';
@import '@/styles/meeting.scss';
@import '@/styles/create-campaign.scss';
@import '@/styles/campaign-settings.scss';
@import '@/styles/responsive/mob_sidebar';
@import '@/styles/responsive/mob_list';
@import '@/styles/pagination.scss';
@import '@/styles/responsive/mob_modal';
@import '@/styles/responsive/mob_player.scss';
@import '@/styles/responsive/mob_pagination.scss';
@import '@/styles/responsive/mob_history-modal';
@import '@/styles/responsive/mob_status-carousel';
</style>
