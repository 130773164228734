import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Suspense as _Suspense, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "wrapper__content" }
const _hoisted_4 = { class: "btn-back__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionPopUp = _resolveComponent("ActionPopUp")!
  const _component_ThankYouPopUp = _resolveComponent("ThankYouPopUp")!
  const _component_MainBar = _resolveComponent("MainBar")!
  const _component_SmallNotification = _resolveComponent("SmallNotification")!
  const _component_header_page = _resolveComponent("header-page")!
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authenticated)
      ? (_openBlock(), _createBlock(_component_ActionPopUp, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_ThankYouPopUp, {
      "is-active": _ctx.showThankYouMessage,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showThankYouMessage = false))
    }, null, 8, ["is-active"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.authenticated)
        ? (_openBlock(), _createBlock(_component_MainBar, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_SmallNotification),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.authenticated)
          ? (_openBlock(), _createBlock(_component_header_page, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["btn-back", { hidden: !_ctx.hasBack }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.breadcrumbStore.back()))
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_SvgIcon, {
              icon: "ic-arr-left",
              width: "16",
              height: "16",
              color: "white"
            })
          ]),
          _createTextVNode(" Back ")
        ], 2),
        _createElementVNode("main", {
          class: _normalizeClass(["main", { main_opacity: !_ctx.authenticated }])
        }, [
          (_openBlock(), _createBlock(_Suspense, null, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_router_view, { key: _ctx.refreshKey }))
            ]),
            _: 1
          }))
        ], 2)
      ])
    ])
  ]))
}