<template>
  <transition name="fade">
    <div class="thank-you-overlay" v-if="isActive">
      <div class="thank-you-message">
        <h3>Thank you for your selection!</h3>
        <p>Your response has been recorded.</p>
        <button @click="closeOverlay">Close</button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ThankYouPopUp',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  setup(_, { emit }) {
    function closeOverlay() {
      emit('close');
    }

    return {
      closeOverlay
    };
  }
});
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.thank-you-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.thank-you-message {
  padding: 2em;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.thank-you-message h3 {
  margin-top: 0;
  color: #4caf50;
}

.thank-you-message button {
  margin-top: 1em;
  padding: 0.5em 1em;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.thank-you-message button:hover {
  background-color: #388e3c;
}
</style>
