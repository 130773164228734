<template>
  <label class="input-check">
    <input
      type="checkbox"
      :checked="modelValue"
      @change="updateCheck"
      class="input-check__checkbox"
    />
    <span v-show="modelValue && onCheck && rowId" class="input-check__mark">
      <svg class="icon ic-tick" width="8" height="6">
        <use xlink:href="@/assets/sprites/sprite.svg#ic-tick"></use>
      </svg>
    </span>
    <span class="input-check__text"><slot></slot></span>
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType, watch } from 'vue';
import { useLeadStore } from '@/store/modules/lead_store';

interface Props {
  modelValue: boolean;
  onCheck?: (id: string | number, state: boolean) => void; // Make this optional
  rowId?: string | number;
  isLeadCheckbox: boolean;
}

export default defineComponent({
  name: 'MyCheckbox',
  props: {
    modelValue: Boolean,
    onCheck: {
      type: Function as PropType<(id: string | number, state: boolean) => void>,
      required: false
    },
    rowId: {
      type: [String, Number],
      required: false
    },
    isLeadCheckbox: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props: Props, { emit }) {
    const leadStore = useLeadStore();

    watch(
      () => leadStore.selectedLeads,
      newVal => {
        if (props.isLeadCheckbox && newVal.length === 0) {
          emit('update:modelValue', false);
        }
      }
    );
    watch(
      () => leadStore.selectAll,
      newVal => {
        if (props.isLeadCheckbox) {
          emit('update:modelValue', newVal);
          if (props.onCheck && props.rowId) {
            props.onCheck(props.rowId, newVal);
          }
        }
      }
    );
    const updateCheck = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit('update:modelValue', target.checked);
      if (props.onCheck && props.rowId) {
        props.onCheck(props.rowId, target.checked);
      }
      if (props.isLeadCheckbox) {
        leadStore.selectAll = leadStore.selectedLeads.length === 10;
      }
    };

    return { updateCheck };
  }
});
</script>

<style scoped></style>
