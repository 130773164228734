import { onUnmounted, Ref } from 'vue';

export default function useClickOutside(
  elementRef: Ref<HTMLElement | null>,
  callback: () => void
): void {
  const handleClick = (e: MouseEvent) => {
    if (elementRef.value && !elementRef.value.contains(e.target as Node)) {
      callback();
    }
  };

  const registerEvent = () => {
    document.addEventListener('click', handleClick);
  };

  const unregisterEvent = () => {
    document.removeEventListener('click', handleClick);
  };

  registerEvent();

  // Cleanup when the component is unmounted
  onUnmounted(unregisterEvent);
}
