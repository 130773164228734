import { RouteRecordRaw } from 'vue-router';

const RecoverPassword = () => import('@/pages/Authentication/RecoverPassword.vue');
const Register = () => import('@/pages/Authentication/Register.vue');
const Login = () => import('@/pages/Authentication/Login.vue');

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/auth/recover-password',
    component: RecoverPassword,
    props: route => ({ token: route.query.token })
  },
  {
    path: '/auth/register',
    component: Register
  },
  {
    path: '/auth/login',
    component: Login
  }
];

export default authRoutes;
