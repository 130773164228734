import { createApp, DefineComponent, watch } from 'vue';
import App from './App.vue';
import components from '@/components/UI/index.js';
import router from './router/router';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import { createPinia, setMapStoreSuffix } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import { useLocalStorage } from '@vueuse/core';
import vuetifyPlugin from './plugins/vuetify';
import { setupGlobalErrorHandler } from '@/plugins/globalErrorHandler';
import svg4everybody from 'svg4everybody';
import MobileDetectionPlugin from '@/plugins/mobileDetection';
import { MOBILE_VIEW_THRESHOLD } from '@/common/lib/constant';
import visibility from '@/common/directives/visibility';

svg4everybody();

export const pinia = createPinia();
pinia.use(
  createPersistedState({
    key: id => `__persisted__${id}`,
    storage: localStorage,
    auto: true
  })
);
setMapStoreSuffix('');

const app = createApp(App);

// Global error handler
setupGlobalErrorHandler(app);

// Register global components
components.forEach((component: DefineComponent) => {
  app.component(component.name as string, component);
});

// Watch for Pinia state changes and persist them to localStorage
watch(
  () => pinia.state.value,
  state => {
    useLocalStorage('pinia', state);
  }
);
app.directive('visibility', visibility);

app
  .use(pinia)
  .use(router)
  .use(vuetifyPlugin)
  .use(VCalendar, { componentPrefix: 'vc', locale: 'en' })
  .use(MobileDetectionPlugin, { thresholdWidth: MOBILE_VIEW_THRESHOLD });

app.mount('#app');
