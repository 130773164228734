import enTranslations from './locales/en.json';
import heTranslations from './locales/he.json';
import { refreshFn } from '@/common/lib/refresh';

const translations = {
  en: enTranslations as Record<string, string>,
  he: heTranslations
} as const;

export type Languages = keyof typeof translations;

export const LanguageNames: { [key in Languages]: string } = {
  en: 'English',
  he: 'Hebrew'
} as const;

let defaultLanguage: Languages = 'en';

export let currentLanguage: Languages = defaultLanguage;
let currentTranslation = translations[currentLanguage];

export function setLanguage(language: Languages) {
  if (!(language in translations)) {
    language = defaultLanguage;
  }
  if (currentLanguage === language) {
    return;
  }
  currentLanguage = language;
  currentTranslation = translations[language];
  document
    .getElementsByTagName('html')[0]
    .classList[currentLanguage === 'he' ? 'add' : 'remove']('rtl');
  localStorage.setItem('language', language);
  refreshFn?.();
}

export function trans(
  key: keyof (typeof translations)[Languages],
  variables?: Record<string, string>
): string {
  return (
    (variables
      ? currentTranslation[key]?.replace(
          /%(\w+)%/g,
          (_: string, varName: string) => variables?.[varName] || _
        )
      : currentTranslation[key]) ?? key
  );
}

export type TranslationKey = keyof (typeof translations)[Languages];

export function _(key: TranslationKey): string {
  return currentTranslation[key] ?? key;
}
