import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
});

instance.interceptors.request.use(
  config => {
    const tokenInStore = localStorage.getItem('token');
    if (tokenInStore) {
      config.headers.Authorization = `Bearer ${tokenInStore}`;
    }
    if (localStorage.getItem('businessId')) {
      config.headers['x-business-id'] = localStorage.getItem('businessId');
    }
    config.withCredentials = true;
    return config;
  },
  error => {
    // Handle request error here
    return Promise.reject(error);
  }
);

export default instance;
