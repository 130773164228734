import { DirectiveBinding } from 'vue';

export default {
  beforeMount(el: HTMLElement) {
    el.style.visibility = 'hidden';
    el.style.opacity = '0';
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value) {
      el.style.visibility = 'visible';
      el.style.opacity = '1';
      el.style.transition = 'opacity 0.4s ease-in-out';
    } else {
      el.style.visibility = 'hidden';
      el.style.opacity = '0';
    }
  }
};
