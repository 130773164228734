import { reactive, readonly } from 'vue';

interface EventBusState {
  openSelectId: string | null;
}

const state = reactive<EventBusState>({
  openSelectId: null
});

const setOpenSelectId = (id: string | null) => {
  state.openSelectId = id;
};

export const useEventBus = () => {
  return {
    state: readonly(state),
    setOpenSelectId
  };
};
