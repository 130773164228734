<template>
  <div class="notice-item" :class="item.isRead ? 'disabled' : ''" @click="navigateToSource">
    <div
      class="notice-item__img"
      :style="{
        'background-image': 'url(' + require('../../assets/img/logo.jpg') + ')'
      }"
    ></div>

    <div class="notice-item__content">
      <div class="notice-item__info">
        <div class="notice-item__text">
          <span class="notice-item__text-accent">{{ item.title }}</span>
          <!--          <span v-if="item.text1">{{ item.text1 }}</span>-->
          <!--          <span class="notice-item__text-accent" v-if="item.text_bold2">{{ item.text_bold2 }}</span>-->
          <!--          <span v-if="item.text2">{{ item.text2 }}</span>-->
        </div>
        <div
          v-if="item.isRead"
          class="notice-item__delete"
          @click.stop="deleteNotification(item._id)"
        >
          <svg class="icon ic-close" width="14" height="14" fill="#4285f4">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-close"></use>
          </svg>
        </div>
        <div v-if="!item.isRead" class="notice-item__circle"></div>
      </div>

      <div class="notice-item__bottom">
        <div class="notice-item__date">{{ formattedDate }}</div>

        <div v-if="item.isRead" class="notice-item__tag notice-item__tag_green already_read">
          <svg class="icon ic-eye" width="12" height="10">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-eye"></use>
          </svg>
          {{ _('Read') }}
        </div>

        <div v-else class="notice-item__tag" @click="markAsRead">
          <svg class="icon ic-tick" width="8" height="8">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-tick"></use>
          </svg>
          {{ _('Mark as read') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { _ } from '@/common/language/translator';
import { INotification } from '@/types/interfaces';
import { getUIStore } from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NotificationItem',
  props: {
    item: {
      type: Object as () => INotification,
      required: true
    }
  },
  emits: ['update:isRead', 'delete'],
  setup(props, { emit }) {
    const router = useRouter();
    const leadView = computed(() => {
      if (getUIStore().leadView === 'cards') {
        return 'cards';
      } else {
        return 'leads';
      }
    });
    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      };
      return (
        new Intl.DateTimeFormat('en-GB', options).format(date) +
        ', at ' +
        date.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })
      );
    };

    const formattedDate = computed(() =>
      props.item.createdAt ? formatDate(props.item.createdAt) : ''
    );

    const markAsRead = () => {
      emit('update:isRead', props.item._id);
    };

    // todo: handle the notification image.
    //  if notification item contains businessId -> need to get the business itself,display the business image
    //  and get the relevant campaign name.

    function navigateToSource() {
      if (props.item.action === 'receiving_a_lead') {
        if (props.item.content.businessId && props.item.content.campaignId) {
          router.push(
            `/b/${props.item.content.businessId}/c/${props.item.content.campaignId}/${leadView.value}`
          );
        }
      }
      emit('update:isRead', props.item._id);
    }

    async function deleteNotification(id: string) {
      emit('delete', id);
    }

    return {
      formattedDate,
      _,
      markAsRead,
      navigateToSource,
      deleteNotification
    };
  }
});
</script>

<style scoped lang="scss"></style>
