<template>
  <header class="header">
    <div v-if="!isMobile" class="btn-element-tooltip">
      <SelectBusiness />
      <!--      TODO: Hidden - reveal the commented out code when the component is ready-->
      <!--      <div class="tooltip">{{ _('Select business') }}</div>-->
    </div>

    <div class="header__content">
      <!--      TODO: Hidden - reveal the commented out code when the component is ready-->
      <!--      <my-button v-if="!isMobile" class="btn_green">-->
      <!--        <svg class="icon ic-shift" width="17" height="17" fill-rule="evenodd">-->
      <!--          <use xlink:href="@/assets/sprites/sprite.svg#ic-shift"></use>-->
      <!--        </svg>-->
      <!--        {{ _('Start shift') }}-->
      <!--      </my-button>-->

      <header-auth v-if="!isMobile" />

      <div class="header-action">
        <!--      TODO: Hidden - reveal the commented out code when the component is ready-->
        <div v-if="!isMobile" class="header-action__item">
          <svg class="icon ic-msg" width="21" height="20">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-msg"></use>
          </svg>
        </div>
        <div
          v-if="isMobile"
          class="header-action__item-menu"
          @click="uiStore.toggleMobileSideBar()"
        >
          <svg class="icon ic-menu-lines" width="18" height="21">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-menu-lines"></use>
          </svg>
        </div>
        <div class="header-action__item" @click="closeNotifications">
          <svg class="icon ic-notice" width="18" height="21">
            <use xlink:href="@/assets/sprites/sprite.svg#ic-notice"></use>
          </svg>
          <div v-if="notificationCount > 0" class="notice-item__circle"></div>
        </div>
      </div>
    </div>
  </header>

  <mobile-sidebar v-if="isMobile" />

  <Transition name="fade">
    <div v-show="isAnyModalOpen" class="site-shadow" @click="closeNotifications"></div>
  </Transition>

  <Notifications :open-notice="openNotice" @close="closeNotifications" />
</template>

<script lang="ts">
import { _ } from '@/common/language/translator';
import { computed, defineComponent, ref } from 'vue';
import SelectBusiness from '@/components/Header/SelectBusiness.vue';
import HeaderAuth from '@/components/Header/HeaderAuth.vue';
import MobileSidebar from '@/mobile_components/Mob_SideBar.vue';
import MyButtonCircle from '../UI/MyButtonCircle.vue';
import MyButton from '@/components/UI/MyButton.vue';
import Notifications from '@/components/Notifications/Notifications.vue';
import SelectCampaign from '@/components/Campaign/CampaignModal/SelectCampaign.vue';
import { useCampaignStore } from '@/store/modules/campaign_store';
import { useNotificationStore } from '@/store/modules/notification_store';
import { getUIStore } from '@/store';
import SvgIcon from '@/components/UI/SvgIcon.vue';

export default defineComponent({
  name: 'HeaderPage',
  components: {
    SelectCampaign,
    Notifications,
    MyButton,
    SelectBusiness,
    HeaderAuth,
    MobileSidebar,
    SvgIcon,
    MyButtonCircle
  },

  setup() {
    const uiStore = getUIStore();
    const campaignStore = useCampaignStore();
    const openNotice = ref(false);
    const campaignSearchOpen = ref(false);
    const notificationCount = computed(() => useNotificationStore().unreadCount);
    const currentCampaign = computed(() => campaignStore.currentCampaign);
    const isMobile = computed(() => uiStore.isMobile);

    const closeNotifications = () => {
      openNotice.value = !openNotice.value;
    };
    const isAnyModalOpen = computed(() => {
      return openNotice.value && !isMobile.value;
    });

    function toggleSideBar() {
      uiStore.toggleSideBar();
    }

    return {
      uiStore,
      isMobile,
      isAnyModalOpen,
      openNotice,
      campaignStore,
      campaignSearchOpen,
      currentCampaign,
      notificationCount,
      closeNotifications,
      toggleSideBar,
      _
    };
  }
});
</script>

<style scoped lang="scss"></style>
