<template>
  <section class="main-bar" :class="{ active: status }">
    <router-link to="/main" class="main-bar-logo">
      <div class="main-bar-logo__short">
        <img src="../../assets/img/logo.svg" alt="" />
      </div>
      <div class="main-bar-logo__full">
        <img src="../../assets/img/logo-full.svg" alt="" />
      </div>
    </router-link>

    <div class="main-bar__content">
      <div v-if="hasBusiness" class="main-bar-menu">
        <router-link
          v-for="(item, index) in menu"
          :key="index"
          class="main-bar-menu__item"
          :class="{ active: item.link == linkActiveClass }"
          :to="item.link"
        >
          <div class="main-bar-menu__icon">
            <SvgIcon
              :icon="item.icon"
              :icon_width="item.icon_width"
              :icon_height="item.icon_height"
            />
          </div>
          <div class="main-bar-menu__text">{{ item.text }}</div>
        </router-link>
      </div>

      <div v-if="hasBusiness" class="main-bar__arr" @click="closeMainBar">
        <svg class="icon ic-arrow-right" width="6" height="11">
          <use xlink:href="../../assets/sprites/sprite.svg#ic-arrow-right"></use>
        </svg>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { _ as t } from '@/common/language/translator';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { USER_ROLES, UserRoleLevels } from '@/types/interfaces';
import { useAuthStore } from '@/store/modules/auth';
import { useCampaignStore } from '@/store/modules/campaign_store';
import { getUIStore } from '@/store';
import SvgIcon from '@/components/UI/SvgIcon.vue';

export default defineComponent({
  name: 'MainBar',
  components: { SvgIcon },

  setup() {
    const uiStore = getUIStore();
    const status = computed(() => uiStore.isMainBarOpen);
    const route = useRoute();
    const authStore = useAuthStore();
    const campaignStore = useCampaignStore();
    const currentBusiness = computed(() => authStore.currentBusiness);
    const currentCampaign = computed(() => campaignStore.currentCampaign);
    const hasCampaignPermission = computed(() => {
      if (currentCampaign.value) {
        const userRoleLevel = UserRoleLevels[currentCampaign.value.role!];
        const minimumRoleLevel = UserRoleLevels[USER_ROLES.Reader];
        return userRoleLevel <= minimumRoleLevel;
      }
      return true;
    });
    const hasBusiness = computed(() => authStore.businesses.length > 0);
    const leadView = computed(() => {
      if (getUIStore().leadView === 'cards') {
        return 'cards';
      } else {
        return 'leads';
      }
    });

    const menu = computed(() => [
      {
        icon: 'ic-home',
        icon_width: '20',
        icon_height: '20',
        text: t('Overview'),
        link: '/',
        permission: true
      },
      {
        icon: 'ic-star',
        icon_width: '20',
        icon_height: '20',
        text: t('Campaigns'),
        link: `/b/${currentBusiness.value?._id}/campaigns`,
        permission: hasCampaignPermission
      },
      ...(currentCampaign.value?._id
        ? [
            {
              icon: 'ic-person',
              icon_width: '16',
              icon_height: '22',
              text: t('Leads'),
              link: `/b/${currentBusiness.value?._id}/c/${currentCampaign.value?._id}/${leadView.value}`,
              permission: hasCampaignPermission
            },
            {
              icon: 'ic-phone',
              icon_width: '20',
              icon_height: '20',
              text: t('Calls'),
              link: `/b/${currentBusiness.value?._id}/c/${currentCampaign.value?._id}/calls`,
              permission: true
            }
          ]
        : []),
      {
        icon: 'ic-people',
        icon_width: '20',
        icon_height: '20',
        text: t('Users'),
        link: `/b/${currentBusiness.value?._id}/users`,
        permission: true
      },
      {
        icon: 'ic-settings',
        icon_width: '20',
        icon_height: '20',
        text: t('Business'),
        link: `/b/${currentBusiness.value?._id}/settings/profile`,
        permission: true
      }
    ]);

    const filteredMenu = computed(() => {
      return menu.value.filter(item =>
        typeof item.permission === 'boolean' ? item.permission : item.permission.value
      );
    });

    const linkActiveClass = computed(() => {
      return filteredMenu.value.find(item => item.link === route.path)?.text || '';
    });

    function closeMainBar() {
      uiStore.toggleSideBar();
    }

    return {
      hasBusiness,
      menu,
      status,
      linkActiveClass,
      filteredMenu,
      t,
      closeMainBar
    };
  }
});
</script>

<style scoped lang="scss"></style>
