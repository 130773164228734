<template>
  <div class="mobile-sidebar" :class="{ open: status }">
    <div class="mob-sidebar-user">
      <div
        class="mob-sidebar-user__img"
        :style="
          currentUser?.image
            ? { 'background-image': `url(${currentUser?.image})` }
            : `background-image: url(${NO_AVATAR_URL()})`
        "
      ></div>

      <div class="mob-sidebar-user__name">{{ currentUser?.name }}</div>
    </div>

    <div class="mob-sidebar-menu">
      <div v-for="item in menuItems" :key="item.key">
        <div class="mob-sidebar-menu__item" @click="toggleMenuItem(item.key)">
          <div class="mob-sidebar-menu__icon">
            <svg :class="'icon ' + item.icon" :width="item.iconWidth" :height="item.iconHeight">
              <use :xlink:href="require(`@/assets/sprites/sprite.svg`) + '#' + item.icon"></use>
            </svg>
          </div>
          <div class="mob-sidebar-menu__label">{{ item.label }}</div>
          <div v-if="item.subMenu" class="mob-sidebar-menu__arrow">
            <svg
              class="icon"
              :class="{ 'ic-arrow-right': !item.isOpen, 'ic-arrow-up': item.isOpen }"
              :width="arrowDimensions(item.isOpen).width"
              :height="arrowDimensions(item.isOpen).height"
            >
              <use
                v-if="!item.isOpen"
                xlink:href="@/assets/sprites/sprite.svg#ic-arrow-right"
              ></use>
              <use v-else xlink:href="@/assets/sprites/sprite.svg#ic-arrow-up"></use>
            </svg>
          </div>
        </div>
        <div v-if="item.subMenu" class="mob-sidebar-submenu" :class="{ open: item.isOpen }">
          <router-link
            v-for="subItem in item.subMenu"
            :key="subItem.key"
            :to="subItem.link"
            class="submenu-link"
          >
            <div class="mob-sidebar-submenu__item">
              <!--              <div class="mob-sidebar-submenu__icon">-->
              <!--                <svg :class="'icon ' + subItem.icon" width="16" height="18">-->
              <!--                  <use-->
              <!--                    :xlink:href="require(`@/assets/sprites/sprite.svg`) + '#' + subItem.icon"-->
              <!--                  ></use>-->
              <!--                </svg>-->
              <!--              </div>-->
              <div class="mob-sidebar-submenu__label">{{ subItem.label }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <span class="mob-sidebar-btn">
      <svg class="icon ic-msg" width="21" height="20">
        <use xlink:href="@/assets/sprites/sprite.svg#ic-msg"></use>
      </svg>
      {{ _('Support') }}
    </span>

    <div class="mob-sidebar-exit" @click.prevent="logoutUser">
      <div class="mob-sidebar-exit__icon">
        <svg class="icon ic-logout-square" width="20" height="20">
          <use xlink:href="@/assets/sprites/sprite.svg#ic-logout-square"></use>
        </svg>
      </div>
      <div class="mob-sidebar-exit__label">{{ _('Logout') }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { _ } from '@/common/language/translator';
import { computed, defineComponent, ref, watch } from 'vue';
import { useAuthStore } from '@/store/modules/auth';
import { getUIStore } from '@/store';
import { NO_AVATAR_URL } from '@/common/lib/getAvatar';
import { useRoute, useRouter } from 'vue-router';
import SelectBusiness from '@/components/Header/SelectBusiness.vue';

interface SubMenuItem {
  key: string;
  link: string;
  icon: string;
  label: string;
}

interface MenuItem {
  key: string;
  icon: string;
  label: string;
  iconWidth: number;
  iconHeight: number;
  isOpen: boolean;
  subMenu?: SubMenuItem[];
}

export default defineComponent({
  name: 'MobileSidebar',
  components: { SelectBusiness },
  setup() {
    const router = useRouter();
    const currentRoute = useRoute();
    const uiStore = getUIStore();
    const authStore = useAuthStore();
    const status = computed(() => uiStore.isMobileSideBarOpen);
    const currentUser = computed(() => authStore.getCurrentUser);
    const currentBusiness = computed(() => authStore.getCurrentBusiness);

    const menuItems = ref<MenuItem[]>([
      {
        key: 'me',
        icon: 'ic-user',
        label: 'Me',
        iconWidth: 21,
        iconHeight: 19,
        isOpen: false,
        subMenu: [
          {
            key: 'profile',
            link: '/user/settings/profile',
            icon: 'ic-user',
            label: 'Profile'
          },
          {
            key: 'shifts',
            link: '/user/settings/shifts',
            icon: 'ic-shift',
            label: 'My Shifts'
          },
          {
            key: 'notifications',
            link: '/user/settings/notifications',
            icon: 'ic-notice',
            label: 'Notifications Settings'
          }
        ]
      },
      {
        key: 'business',
        icon: 'ic-briefcase',
        label: currentBusiness.value?.name || 'Business',
        iconWidth: 21,
        iconHeight: 19,
        isOpen: false,
        subMenu: [
          {
            key: 'business-info',
            link: `/b/${currentBusiness.value?._id}/settings/profile`,
            icon: 'ic-briefcase',
            label: 'Business Info'
          },
          {
            key: 'integrations',
            link: `/b/${currentBusiness.value?._id}/settings/integrations`,
            icon: 'ic-link',
            label: 'Integrations'
          },
          {
            key: 'business-notifications',
            link: `/b/${currentBusiness.value?._id}/settings/leads-statuses`,
            icon: 'ic-note',
            label: 'Notifications Settings'
          },
          {
            key: 'api-keys',
            link: `/b/${currentBusiness.value?._id}/settings/api-keys`,
            icon: 'ic-api-key',
            label: 'API Keys'
          },
          {
            key: 'business-create',
            link: '/business/create',
            icon: 'ic-plus',
            label: 'Create business'
          }
        ]
      },
      {
        key: 'dashboard',
        icon: 'ic-dashboard',
        label: 'Dashboard',
        iconWidth: 18,
        iconHeight: 18,
        isOpen: false
      }
      // {
      //   key: 'projects',
      //   icon: 'ic-projects',
      //   label: 'Projects',
      //   iconWidth: 19,
      //   iconHeight: 19,
      //   isOpen: false
      // }
      // {
      //   key: 'calendar',
      //   icon: 'ic-calendar',
      //   label: 'Calendar',
      //   iconWidth: 18,
      //   iconHeight: 20,
      //   isOpen: false
      // },
      // {
      //   key: 'messenger',
      //   icon: 'ic-messenger',
      //   label: 'Messenger',
      //   iconWidth: 21,
      //   iconHeight: 19,
      //   isOpen: false
      // },
      // {
      //   key: 'info-portal',
      //   icon: 'ic-folder',
      //   label: 'Info Portal',
      //   iconWidth: 21,
      //   iconHeight: 16,
      //   isOpen: false
      // }
    ]);

    watch(
      () => authStore.isLoggedIn,
      newVal => {
        authStore.setLoggedInState(newVal);
      },
      { immediate: true }
    );

    watch(currentRoute, () => {
      uiStore.toggleMobileSideBar(false);
    });

    async function logoutUser(): Promise<void> {
      try {
        authStore.logout();
        await router.push('/auth/login');
      } catch (error) {
        console.error(_('Failed to logout'), error);
      }
    }

    function toggleMenuItem(key: string): void {
      menuItems.value.forEach(item => {
        if (item.key === key) {
          item.isOpen = !item.isOpen;
        } else {
          item.isOpen = false;
        }
      });
    }

    function arrowDimensions(isOpen: boolean): { width: number; height: number } {
      return isOpen ? { width: 11, height: 6 } : { width: 6, height: 11 };
    }

    return {
      menuItems,
      currentUser,
      status,
      authStore,
      logoutUser,
      toggleMenuItem,
      arrowDimensions,
      _
    };
  },
  methods: {
    NO_AVATAR_URL() {
      return NO_AVATAR_URL;
    }
  }
});
</script>

<style scoped lang="scss">
//.btn_primary {
//  background-color: #4154ef;
//  color: white;
//}
</style>
