import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationItem = _resolveComponent("NotificationItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tabs-page__content-item", { active: _ctx.isActive }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_NotificationItem, {
        key: index,
        item: item,
        "onUpdate:isRead": _ctx.markAsRead,
        onDelete: _ctx.handleDelete
      }, null, 8, ["item", "onUpdate:isRead", "onDelete"]))
    }), 128))
  ], 2))
}