<template>
  <svg
    :class="'icon ' + className"
    :width="icon_width"
    :height="icon_height"
    :viewBox="viewBox"
    :style="{ fill: color }"
  >
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    icon_width: {
      type: [String, Number],
      default: '16'
    },
    icon_height: {
      type: [String, Number],
      default: '16'
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const iconPath = computed(() => {
      return require(`@/assets/sprites/sprite.svg`) + '#' + props.icon;
    });

    return { iconPath };
  }
});
</script>

<style scoped>
svg {
  display: inline-block;
  fill: currentColor; /* Allows the icon color to be changed using font color */
}
</style>
