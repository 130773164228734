<template>
  <router-link
    v-if="link"
    :to="link"
    class="btn-circle"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <slot></slot>
    <svg
      v-if="icon"
      :class="`icon ic-${icon}`"
      :width="iconWidth"
      :height="iconHeight"
      :fill="iconFill"
    >
      <use :xlink:href="`${require('@/assets/sprites/sprite.svg')}#ic-${icon}`"></use>
    </svg>
    <span v-if="tooltip" v-show="showTooltip" class="tooltip">{{ tooltip }}</span>
  </router-link>

  <div v-else class="btn-circle" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
    <slot></slot>
    <svg
      v-if="icon"
      :class="`icon ic-${icon}`"
      :width="iconWidth"
      :height="iconHeight"
      :fill="iconFill"
    >
      <use :xlink:href="`${require('@/assets/sprites/sprite.svg')}#ic-${icon}`"></use>
    </svg>
    <span v-if="tooltip" v-show="showTooltip" class="tooltip">{{ tooltip }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MyButtonCircle',
  props: {
    link: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: '',
      required: false
    },
    iconFill: {
      type: String,
      required: false
    },
    iconWidth: {
      type: String,
      default: '12',
      required: false
    },
    iconHeight: {
      type: String,
      default: '12',
      required: false
    }
  },
  setup() {
    const showTooltip = ref(false);
    return {
      showTooltip
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';

.btn-circle {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #000;
  cursor: pointer;
  transition: background-color 0.2s ease 0s;
  font-family: 'Poppins', Arial, sans-serif;

  .icon {
    fill: #fff;
  }

  &:hover {
    background: #3b3b3b;
  }

  &_big {
    width: 40px;
    height: 40px;
  }

  &_green {
    background: rgba(15, 173, 104, 0.06);
    color: #0fad68;

    .icon {
      fill: #0fad68;
    }

    &:hover {
      background: rgba(15, 173, 104, 0.15);
      color: #0fad68;
    }
  }

  &_red {
    background: $color-red;
    color: #fff;

    .icon {
      fill: #fff;
    }

    &:hover {
      background: #f73643;
    }
  }

  &_red-light {
    background: rgba(255, 77, 90, 0.06);
    color: #fff;

    .icon {
      fill: $color-red;
    }

    &:hover {
      background: rgba(255, 77, 90, 0.15);
      color: #fff;
    }
  }

  &_grey {
    background: #f5f5f9;

    .icon {
      fill: #000;
    }

    &:hover .icon {
      fill: #fff;
    }
  }

  &_border {
    background: #fff;
    border: 1px solid #f5f5f9;
    color: #000;

    .icon {
      fill: #000;
    }

    &:hover {
      color: #000;
      background: #f5f5f9;
    }
  }

  &_border-dark {
    background: #fff;
    border: 1px solid #000;
    color: #000;

    .icon {
      fill: #000;
    }

    &:hover {
      color: #fff;
      background: #000;

      .icon {
        fill: #fff;
      }
    }
  }

  &_white {
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
  }

  &_white-fill {
    background: #fff;
    color: #000;

    .icon {
      fill: #000;
    }

    &:hover {
      background: #3b3b3b;
      color: #fff;

      .icon {
        fill: #fff;
      }
    }
  }

  &_opacity {
    background: transparent;

    .icon {
      fill: #000;
    }

    &:hover {
      background: #f5f5f9;
    }

    &:hover .icon {
      fill: #000;
    }
  }

  &_middle {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  &_small {
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }
}

.tooltip {
  visibility: hidden;
  position: absolute;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.6;
  max-height: 30px;
  z-index: 9999;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  transition:
    opacity 0.3s,
    visibility 0.3s;
}

.btn-circle:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 991px) {
  .tooltip {
    display: none;
  }
}
</style>
